<template>
  <BOverlay
    :show="showOverlay"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <reunion-apoderado-form
      btnSubmit="Editar Reunión"
      :reunion.sync="selectedReunion"
      @processForm="editar"
    />
  </BOverlay>

</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import reunionApoderadoForm from './ReunionApoderadoForm.vue'

export default {
  components: {
    BOverlay,
    reunionApoderadoForm,
  },
  data() {
    return {
      showOverlay: false,
    }
  },
  computed: {
    ...mapState('reuniones', ['selectedReunion']),
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      updateReunion: 'reuniones/updateReunion',
    }),
    editar(reunion) {
      this.showOverlay = true
      this.updateReunion(reunion).then(() => {
        const statusReunion = store.state.reuniones.status
        if (statusReunion === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Reunión guardada 👍',
              icon: 'CheckIcon',
              text: 'La reunión fue editada con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.$router.replace({
            name: 'reuniones-apoderados',
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: store.state.reuniones.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.showOverlay = false
      })
    },
  },
}
</script>
